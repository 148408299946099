import Vue from 'vue'
import './plugins/snotify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './scripts/router'
import store from './store'
import LocalConfig from './class/LocalConfig'
import vuetify from './plugins/vuetify'
import storage from './plugins/storage'
import API from './scripts/api'
import '@babel/polyfill'
import VueNativeNotification from 'vue-native-notification'

Vue.use(require('vue-moment'))
Vue.use(storage, { databaseName: 'mbx' })
Vue.use(VueAxios, axios)
Vue.use(VueNativeNotification, { requestOnNotify: true })
Vue.config.productionTip = false
Vue.prototype.$lConfig = new LocalConfig()
Vue.prototype.$API = API

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
