<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <router-view
      name="appBar"
      @toggleMenu="
        () => {
          this.drawer = !this.drawer
        }
      "
    />
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="false"
      app
      fixed
      v-if="$router.currentRoute.meta.ShowMenu"
    >
      <v-list-item
        two-line
        @click="$router.push('/me')"
        :style="
          $router.currentRoute.name == 'me'
            ? {
                'background-color': $vuetify.theme.dark ? '#5C5C5C' : '#EAEAEA'
              }
            : {}
        "
      >
        <v-list-item-avatar>
          <v-icon>fas fa-user</v-icon>
        </v-list-item-avatar>
        <v-list-item-content v-if="$lConfig && $lConfig.User">
          <v-list-item-title>{{ $lConfig.User.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ $lConfig.User.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <div v-for="(item, ixmi) in menu_items" :key="ixmi">
        <v-list-item
          v-if="
            $lConfig.User && $lConfig.User.access_level >= item.access_level
          "
          link
          @click="$router.push(item.route).catch(_err => {})"
          :style="
            $router.currentRoute.name == item.name
              ? {
                  'background-color': $vuetify.theme.dark
                    ? '#5C5C5C'
                    : '#EAEAEA'
                }
              : {}
          "
        >
          <v-list-item-icon color="primary">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-badge color="red" :content="item.news" v-if="item.news > 0">
                {{ item.title }}
              </v-badge>
              <span v-else>{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <router-view name="sidebarItems"></router-view>
      <v-spacer></v-spacer>
      <v-footer fixed class="pa-0">
        <v-btn
          class="mx-auto"
          style="width: 50%"
          right
          @click="
            $vuetify.theme.dark = !$vuetify.theme.dark
            $lConfig.Dark = $vuetify.theme.dark
          "
        >
          <v-icon class="mr-2">fas fa-adjust</v-icon>
          {{ $vuetify.theme.dark ? 'LIGHT' : 'DARK' }}
        </v-btn>
        <v-btn
          class="mx-auto"
          style="width: 50%"
          right
          color="error"
          @click="
            $lConfig.AccessToken = null
            $lConfig.User = null
            $router.go()
          "
          >log out<v-icon class="ml-2">fas fa-sign-out-alt</v-icon></v-btn
        >
      </v-footer>
    </v-navigation-drawer>
    <v-content fluid style="overflow: hidden;">
      <keep-alive exclude="Groups">
        <router-view
          @toggleMenu="
            () => {
              this.drawer = !this.drawer
            }
          "
        ></router-view>
      </keep-alive>
    </v-content>
  </v-app>
</template>
<style lang="sass">
@import './../node_modules/typeface-roboto/index.css'
</style>
<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: true,
    mini: true,
    menu_items: [
      {
        title: 'Início',
        name: 'home',
        icon: 'fas fa-home',
        route: '/main',
        news: 0,
        access_level: 0
      },
      {
        title: 'Grupos',
        name: 'groups',
        icon: 'fas fa-users',
        route: '/groups',
        news: 0,
        access_level: 0
      },
      {
        title: 'Users',
        name: 'users',
        icon: 'fas fa-users',
        route: '/users',
        news: 0,
        access_level: 777
      }
    ]
  }),
  mounted() {},
  created() {
    this.$vuetify.theme.dark = this.$lConfig.Dark
  },
  methods: {},
  watch: {}
}
</script>
